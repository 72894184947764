import {
    STATE,
    ACTIONS,
    MUTATIONS,
    GETTERS
} from "./constants";
import {
    mapEntityToPayload,
    mapEntityTypesResponse,
    mapEntityPurposesResponse,
} from "./helpers";
import {http, routes} from "Figured/Assets/Modules";
import merge from "lodash/merge";
import get from "lodash/get";

export default {
    namespaced: true,

    state: {
        [STATE.LOADING]: false,
        [STATE.BUSINESS_STRUCTURE]: {},
        [STATE.ERROR_MESSAGE]: null,
        [STATE.ENTITY_PURPOSES]: null,
        [STATE.ENTITY_TYPES]: null,
        [STATE.VALIDATION_MESSAGES]: {},
        [STATE.AVAILABLE_BUSINESS_STRUCTURES]: [],
        [STATE.AVAILABLE_FARMS]: [],
        [STATE.AVAILABLE_FARMS_LOADING]: false
    },

    actions: {
        async [ACTIONS.STORE_ENTITY](context, entity) {
            context.commit(MUTATIONS.SET_LOADING, true);

            const [uri, httpVerb, routeParams] = entity.id
                ? ["api.farm_business_structure.entities.update", "put", {entityId: entity.id}]
                : ["api.farm_business_structure.entities.store", "post", null];

            try {
                const response = await http[httpVerb](routes.get(uri, routeParams), mapEntityToPayload(entity));
                // 201: brings new entity data on response.data -- partial (only id) or not, it will get merged with payload
                // 204, we just want to refresh the vuex store with the, cloned and, modified data
                const entityResponse = get(response, "data.data", {})
                const businessStructureResponse = get(response, "data.meta.farmBusinessStructure", {})
                entity = merge(entity, entityResponse);
                context.commit(MUTATIONS.UPSERT_BUSINESS_STRUCTURE, businessStructureResponse);
                context.commit(MUTATIONS.PUT_ENTITY_IN_BUSINESS_STRUCTURE, entity);

                return true;
            } catch (e) {
                context.commit(MUTATIONS.HANDLE_RESPONSE_ERROR, e);
            } finally {
                context.commit(MUTATIONS.SET_LOADING, false);
            }

            return false;
        },
        [ACTIONS.LOAD_BUSINESS_STRUCTURE]: async function(context) {
            context.commit(MUTATIONS.SET_LOADING, true);

            try {
                const response = await http.get(routes.get("api.farm_business_structure.show"));
                context.commit(MUTATIONS.UPSERT_BUSINESS_STRUCTURE, response.data.data);
                context.commit(MUTATIONS.SET_ENTITY_TYPES, mapEntityTypesResponse(response.data.meta));
                context.commit(MUTATIONS.SET_ENTITY_PURPOSES, mapEntityPurposesResponse(response.data.meta));
            } catch (err) {
                context.commit(MUTATIONS.HANDLE_RESPONSE_ERROR, err);
            } finally {
                context.commit(MUTATIONS.SET_LOADING, false);
            }
        },
        [ACTIONS.LOAD_AVAILABLE_BUSINESS_STRUCTURES]: async function(context) {
            try {
                const response = await http.get(routes.get("api.farm_business_structures"));
                context.commit(MUTATIONS.SET_AVAILABLE_BUSINESS_STRUCTURES, response.data.data);
            } catch (err) {
                context.commit(MUTATIONS.HANDLE_RESPONSE_ERROR, err);
            }
        },
        [ACTIONS.LOAD_AVAILABLE_FARMS]: async function(context, name_begins_with) {
            if (!name_begins_with) {
                return;
            }
            try {
                context.commit(MUTATIONS.SET_AVAILABLE_FARMS_LOADING, true);
                const response = await http.get(routes.get("api.farm_business_structure.available_farms", {}, {
                    name_begins_with
                }));
                context.commit(MUTATIONS.SET_AVAILABLE_FARMS, response.data.data);
            } catch (err) {
                context.commit(MUTATIONS.HANDLE_RESPONSE_ERROR, err);
            } finally {
                context.commit(MUTATIONS.SET_AVAILABLE_FARMS_LOADING, false);
            }
        },
        [ACTIONS.CLEAR_FARMS](context) {
            context.commit(MUTATIONS.SET_AVAILABLE_FARMS, []);
        },
        [ACTIONS.SET_IS_COMPLETE]: async function(context, is_complete) {
            context.commit(MUTATIONS.SET_LOADING, true);

            const newBusinessStructure = Object.assign({}, context.state[STATE.BUSINESS_STRUCTURE], {
                is_complete
            });

            try {
                await http.patch(routes.get(
                    "api.farm_business_structure.update_status",
                    {uuid: context.state[STATE.BUSINESS_STRUCTURE].id}), {
                    "is_complete": is_complete,
                });
                context.commit(MUTATIONS.SET_BUSINESS_STRUCTURE, newBusinessStructure)
            } catch (err) {
                context.commit(MUTATIONS.HANDLE_RESPONSE_ERROR, err);
            } finally {
                context.commit(MUTATIONS.SET_LOADING, false);
            }

        },
        [ACTIONS.DELETE_ENTITY]: async function(context, requestData) {
            context.commit(MUTATIONS.SET_LOADING, true);

            let data = {
                confirm: requestData.confirm,
            };

            try {
                await http.delete(routes.get(
                    "api.farm_business_structure.entities.delete",
                    {entityId: requestData.entityId}), { data });
                //* return the most up-to-date version of the business structure from the DB
                await context.dispatch(ACTIONS.LOAD_BUSINESS_STRUCTURE);

                return true;
            } catch (err) {
                context.commit(MUTATIONS.HANDLE_RESPONSE_ERROR, err);
            } finally {
                context.commit(MUTATIONS.SET_LOADING, false);
            }

            return false;
        },
        [ACTIONS.CLEAR_VALIDATION_MESSAGES]: function(context) {
            context.commit(MUTATIONS.SET_VALIDATION_MESSAGES, {});
        },
        [ACTIONS.MERGE_BUSINESS_STRUCTURE]: async function(context, newBusinessStructureId) {
            try {
                context.commit(MUTATIONS.SET_LOADING, true);
                await http.patch(routes.get("api.farm_business_structure.change"), {
                    "newId": newBusinessStructureId,
                });
                await context.dispatch(ACTIONS.LOAD_BUSINESS_STRUCTURE);
                context.commit(MUTATIONS.CLEAR_VALIDATION_MESSAGES);

                return true;
            } catch (err) {
                context.commit(MUTATIONS.HANDLE_RESPONSE_ERROR, err)
            } finally {
                context.commit(MUTATIONS.SET_LOADING, false);
            }

            return false;
        },
        [ACTIONS.UPDATE_BUSINESS_STRUCTURE]: async function({ commit, state }, name) {
            try {
                commit(MUTATIONS.SET_LOADING, true);
                await http.put(routes.get("api.farm_business_structure.update"), {
                    "id": state[STATE.BUSINESS_STRUCTURE].id,
                    "name": name,
                });

                commit(MUTATIONS.UPDATE_BUSINESS_STRUCTURE_NAME, name);
                commit(MUTATIONS.CLEAR_VALIDATION_MESSAGES);

                return true;
            } catch (err) {
                commit(MUTATIONS.HANDLE_RESPONSE_ERROR, err)
            } finally {
                commit(MUTATIONS.SET_LOADING, false);
            }

            return false;
        },
    },

    mutations: {
        [MUTATIONS.HANDLE_RESPONSE_ERROR](state, err) {
            if (!err.response) {
                console.error(err);
                return;
            }
            console.log("HANDLE_RESPONSE_ERROR", err);
            switch(err.response.status) {
                case 422:
                    state[STATE.VALIDATION_MESSAGES] = err.response.data;
                    break;
                case 403:
                    state[STATE.VALIDATION_MESSAGES] = {
                        notAllowed: get(err.response, "data.message")
                    };
                    break;
                default:
                    state[STATE.ERROR_MESSAGE] = err.message;
            }
        },
        [MUTATIONS.SET_LOADING](state, isLoading) {
            state[STATE.LOADING] = isLoading;
        },
        [MUTATIONS.SET_ENTITY_TYPES](state, entityTypes) {
            state[STATE.ENTITY_TYPES] = entityTypes;
        },
        [MUTATIONS.SET_ENTITY_PURPOSES](state, entityPurposes) {
            state[STATE.ENTITY_PURPOSES] = entityPurposes;
        },
        [MUTATIONS.SET_BUSINESS_STRUCTURE](state, businessStructure) {
            state[STATE.BUSINESS_STRUCTURE] = businessStructure;
        },
        [MUTATIONS.SET_ERROR_MESSAGE](state, errorMessage) {
            state[STATE.ERROR_MESSAGE] = errorMessage;
        },
        [MUTATIONS.UPSERT_BUSINESS_STRUCTURE](state, newBusinessStructure) {
            state[STATE.BUSINESS_STRUCTURE] = {...state[STATE.BUSINESS_STRUCTURE], ...newBusinessStructure};
        },
        [MUTATIONS.UPDATE_BUSINESS_STRUCTURE_NAME](state, name) {
            state[STATE.BUSINESS_STRUCTURE].name = name;
        },
        /**
         * @param state
         * @param updatedEntity by the time this gets executed, this entity always has id
         */
        [MUTATIONS.PUT_ENTITY_IN_BUSINESS_STRUCTURE](state, updatedEntity) {
            let entities = state[STATE.BUSINESS_STRUCTURE].entities;
            entities = entities.filter(e => e.id); // remove templates to avoid upsert edge-cases.
            const idx = entities.findIndex(e => e.id === updatedEntity.id); // or -1 when new (not found).

            idx !== -1
                ? (entities[idx] = updatedEntity)
                : entities.push(updatedEntity);

            state[STATE.BUSINESS_STRUCTURE].entities = entities;
        },

        [MUTATIONS.SET_VALIDATION_MESSAGES](state, validationMessages) {
            state[STATE.VALIDATION_MESSAGES] = validationMessages;
        },

        [MUTATIONS.CLEAR_VALIDATION_MESSAGES](state) {
            let revalidated = {...state[STATE.VALIDATION_MESSAGES]};

            if (state[STATE.BUSINESS_STRUCTURE].name) {
                delete revalidated["business_structure.name"];
            }
            // add more re-validations here...

            state[STATE.VALIDATION_MESSAGES] = revalidated;
        },

        [MUTATIONS.SET_AVAILABLE_BUSINESS_STRUCTURES](state, businessStructures) {
            state[STATE.AVAILABLE_BUSINESS_STRUCTURES] = businessStructures;
        },

        [MUTATIONS.SET_AVAILABLE_FARMS](state, farms) {
            state[STATE.AVAILABLE_FARMS] = farms;
        },

        [MUTATIONS.SET_AVAILABLE_FARMS_LOADING](state, loading) {
            state[STATE.AVAILABLE_FARMS_LOADING] = loading;
        }
    },

    getters: {
        [GETTERS.IS_COMPLETE](state) {
            const businessStructure = state[STATE.BUSINESS_STRUCTURE];

            return businessStructure ? businessStructure.is_complete : false;
        }
    }
}